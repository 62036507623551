// import Button from '@src/components/form/Button'
import { IconButton, Grid } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cookie from 'react-cookies';
import { RESET } from '@src/redux/ui/actions';
import Sidebar from './Drawer';
import MenuIcon from '@mui/icons-material/Menu';

function RootLayout() {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const dispatchStore = useDispatch();
    const path = window.location.pathname;

    const handleLogout = () => {
        sessionStorage.clear();
        dispatchStore({ type: RESET });
        dispatchStore({ type: 'SIGNED_OUT', val: false });
        cookie.remove('token_econocom');
        navigate('/');
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <div>
            {path.length > 2 ? (
                <>
                    <header className="navigation" style={{ zIndex: '20000', position: 'fixed', top: '0', display: 'flex', justifyContent: 'space-between', backgroundColor: '#3e0d81' }}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
                            <MenuIcon />
                        </IconButton>
                        <div className="logoContainer">
                            <img src="/images/logo.svg" />
                        </div>
                    </header>
                    <Sidebar handleLogout={handleLogout} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                </>
            ) : (
                ''
            )}
            <Grid sx={{ flexGrow: 1 }} container justifyContent="center">
                <Grid item xs={12}>
                    <Outlet />
                </Grid>
            </Grid>
        </div>
    );
}

export default RootLayout;

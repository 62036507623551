export const dateFormatterBe = (target: any): string => {
    return (
        `${target?.getDate().toString().length === 1 ? `0${target?.getDate()}` : target?.getDate()}` +
        `-${`${target?.getMonth() + 1}`.toString().length === 1 ? `0${target?.getMonth() + 1}` : `${target?.getMonth() + 1}`}-${target?.getFullYear()}`
    );
};
export const dateFormatter = (target: any): string => {
    return (
        `${target?.getFullYear()}-${`${target?.getMonth() + 1}`.toString().length === 1 ? `0${target?.getMonth() + 1}` : `${target?.getMonth() + 1}`}-` +
        `${target?.getDate().toString().length === 1 ? `0${target?.getDate()}` : target?.getDate()}`
    );
};

/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import cookie from 'react-cookies';

// import { AuthContext } from './authContext';
import RootState from '@src/interfaces/RootState';
import { useTranslation } from 'react-i18next';
import { REFRESH_TOKEN } from '@src/redux/auth/actions';
import { SET_LOADING } from '@src/redux/ui/actions';
import AuthService from '@src/api/services/authService';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PrivateRoute = ({ dummy }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const isAuthenticated = useSelector((state: RootState) => state?.auth?.isAuthenticated);
    const navigate = useNavigate();

    /* FUNCTIONS */
    const logOut = () => {
        console.log('logOut...');
        sessionStorage.clear();
        dispatchStore({ type: 'SIGNED_OUT', val: false });
        navigate('/');
    };

    const handleLogout = () => {
        console.log('handleLogout');
        if (isAuthenticated) {
            logOut();
        }
    };

    const handleOnIdle = () => {
        if (isAuthenticated) {
            refreshToken();
            reset();
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleOnActive = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handleOnAction = () => {};

    const { reset } = useIdleTimer({
        timeout: 1000 * 180 * 1,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        events: [dummy],
        debounce: 500,
    });

    const { getRemainingTime } = useIdleTimer({
        timeout: 1000 * 1800 * 1,
        onIdle: handleLogout,
        debounce: 500,
    });

    /* USEEFFECTS */
    useEffect(() => {
        if (!isAuthenticated) {
            console.log('Logged out');
            navigate('/');
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            refreshToken();
        } else {
            logOut();
        }
    }, []);
    useEffect(() => {
        dispatchStore({ type: REFRESH_TOKEN });
    }, []);
    const refreshToken = () => {
        dispatchStore({ type: SET_LOADING, val: true });
        AuthService.tokenRefresh()
            .then((response) => {
                sessionStorage.setItem('token_econocom', `${response.headers.authorization}`);
                dispatchStore({ type: SET_LOADING, val: false });
            })
            .catch((err) => {
                sessionStorage.clear();
                cookie.remove('token_econocom');
            });
    };

    return <>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</>;
};

export default PrivateRoute;
